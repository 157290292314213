import { Component, inject, InjectionToken, OnInit, ViewEncapsulation } from '@angular/core';
import { ISpace, SpaceModule, SpaceService } from '@flatfile/angular-sdk';

export const FLATFILE_WRAPPER_DATA_INJECTION_TOKEN = new InjectionToken<FlatfileSpaceConfig>(
  'FLATFILE_WRAPPER_DATA_INJECTION_TOKEN',
);

export type FlatfileSpaceConfig = Pick<
  ISpace,
  'environmentId' | 'publishableKey' | 'workbook' | 'listener' | 'closeSpace' | 'userInfo'
>;

const commonFlatFileOptions: Readonly<Partial<ISpace>> = {
  name: 'Clover',
  displayAsModal: false,
  iframeStyles: {
    maxWidth: 'calc(100vw - 30px)',
    maxHeight: 'calc(100vh - 30px)',
    width: '1200px',
    height: '800px',
    borderRadius: '8px',
    background: 'white',
  },
  themeConfig: {
    root: {
      primaryColor: '#1ea2e2',
      dangerColor: '#ea292a',
      warningColor: '#f2994a',
      successColor: '#27ae60',
      actionColor: '#1ea2e2',
      borderColor: '#dce6f0',
      buttonBorderRadius: '8px',
      badgeBorderColor: '#1ea2e2',
      badgeBorderRadius: '4px',
      checkboxBorderColor: '#64696e',
      checkboxBorderRadius: '6px',
      interactiveBorderColor: '#dce6f0',
      interactiveBorderRadius: '4px',
      tabstripActiveColor: '#1ea2e2',
      tabstripInactiveColor: '#46505a',
      tabstripHoverTextColor: '#0aa8f5',
      tabstripHoverBorderColor: '#0aa8f5',
      modalBorderRadius: '8px',
      pillBorderRadius: '4px',
      popoverBackgroundColor: '#28323c',
      popoverBorderRadius: '6px',
      tooltipBackgroundColor: '#28323c',
    },
    table: {
      cell: {
        active: {
          borderColor: '#1ea2e2',
          spinnerColor: '#1ea2e2',
        },
        selected: {
          backgroundColor: '#279fd91a',
        },
      },
      inputs: {
        checkbox: {
          color: '#1ea2e2',
          borderColor: '#279fd91a',
        },
        radio: {
          color: '#1ea2e2',
        },
      },
      filters: {
        color: '#1ea2e2',
        outerBorder: 'none',
        innerBorderRadius: '4px',
        outerBorderRadius: '4px',
        active: {
          backgroundColor: '#1ea2e2',
        },
        error: {
          activeBackgroundColor: '#ea292a',
        },
      },
      buttons: {
        iconColor: '#1ea2e2',
        pill: {
          backgroundColor: '#279fd91a',
          color: '#1ea2e2',
        },
      },
    },
  },
};

@Component({
  selector: 'cc-flatfile-space-wrapper',
  standalone: true,
  imports: [SpaceModule],
  template: `
    <div class="flatfile-space-wrapper">
      <flatfile-space [spaceProps]="spaceProps"></flatfile-space>
    </div>
  `,
  styleUrl: './flatfile-space-wrapper.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FlatfileSpaceWrapperComponent implements OnInit {
  protected readonly spaceConfig: FlatfileSpaceConfig = inject(FLATFILE_WRAPPER_DATA_INJECTION_TOKEN);

  private readonly spaceService = inject(SpaceService);

  protected get spaceProps(): ISpace {
    return {
      ...commonFlatFileOptions,
      ...this.spaceConfig,
    } as ISpace;
  }

  ngOnInit(): void {
    this.spaceService.OpenEmbed();
  }
}
