import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewEncapsulation, Input, Output, NgModule } from '@angular/core';
import { supported, create } from 'filepond';
export { registerPlugin } from 'filepond';
import { CommonModule } from '@angular/common';

// We test if filepond is supported on the current client
const isSupported = supported();
// List of attributes for fallback input
const inputAttributes = ["id", "name", "class", "multiple", "required", "disabled", "capture", "accept"];
// Methods not made available on the component
const filteredComponentMethods = ["setOptions", "on", "off", "onOnce", "appendTo", "insertAfter", "insertBefore", "isAttachedTo", "replaceElement", "restoreElement", "destroy"];
const outputs = ["oninit", "onwarning", "onerror", "oninitfile", "onaddfilestart", "onaddfileprogress", "onaddfile", "onprocessfilestart", "onprocessfileprogress", "onprocessfileabort", "onprocessfilerevert", "onprocessfile", "onprocessfiles", "onremovefile", "onpreparefile", "onupdatefiles", "onactivatefile", "onreorderfiles"];
// Component outline
class FilePondComponent {
  constructor(root, zone) {
    this.options = {};
    // same as outputs array
    this.oninit = new EventEmitter();
    this.onwarning = new EventEmitter();
    this.onerror = new EventEmitter();
    this.oninitfile = new EventEmitter();
    this.onactivatefile = new EventEmitter();
    this.onaddfilestart = new EventEmitter();
    this.onaddfileprogress = new EventEmitter();
    this.onaddfile = new EventEmitter();
    this.onprocessfilestart = new EventEmitter();
    this.onprocessfileprogress = new EventEmitter();
    this.onprocessfileabort = new EventEmitter();
    this.onprocessfilerevert = new EventEmitter();
    this.onprocessfile = new EventEmitter();
    this.onprocessfiles = new EventEmitter();
    this.onremovefile = new EventEmitter();
    this.onpreparefile = new EventEmitter();
    this.onupdatefiles = new EventEmitter();
    this.onreorderfiles = new EventEmitter();
    this.pond = null;
    this.handleEvent = null;
    this.root = root;
    this.zone = zone;
  }
  ngAfterViewInit() {
    const input = this.root.nativeElement.querySelector("input");
    // transfer relevant attributes to input, this so we still have an input with the correct attributes should file pond not load
    const attributes = this.root.nativeElement.attributes;
    inputAttributes.forEach(name => {
      const value = attributes[name] ? attributes[name].value : this.options[name];
      if (!value) {
        return;
      }
      input.setAttribute(name, value);
    });
    // no sufficient features supported in this browser
    if (!isSupported) {
      return;
    }
    // map FilePond events to Angular @outputs
    this.handleEvent = e => {
      const key = `on${e.type.split(":")[1]}`;
      // @ts-ignore
      this[key].emit({
        ...e.detail
      });
    };
    outputs.forEach(event => {
      this.root.nativeElement.addEventListener(`FilePond:${event.substring(2)}`, this.handleEvent);
    });
    // will block angular from listening to events inside the pond
    this.zone.runOutsideAngular(() => {
      // create instance
      this.pond = create(input, {
        // our options
        ...this.options,
        // our initial files
        files: this.files
      });
    });
    // Copy instance method references to component instance
    this.pond && Object.keys(this.pond)
    // remove unwanted methods
    .filter(key => filteredComponentMethods.indexOf(key) === -1)
    // set method references from the component instance to the pond instance
    .forEach(key => {
      // @ts-ignore
      this[key] = this.pond[key];
    });
  }
  ngOnChanges(changes) {
    // no need to handle first change
    if (changes["firstChange"]) return;
    // no filepond instance available
    if (!this.pond) {
      return;
    }
    // use new options object as base ( or if not available, use current options )
    const options = changes["options"] ? changes["options"].currentValue : this.options;
    // see if file list has changed
    if (changes["files"] && JSON.stringify(changes["files"].previousValue) !== JSON.stringify(changes["files"].currentValue)) {
      // file list has changed
      options.files = changes["files"].currentValue;
    }
    // set new options
    this.pond.setOptions(options);
  }
  ngOnDestroy() {
    if (!this.pond) {
      return;
    }
    outputs.forEach(event => {
      this.root.nativeElement.removeEventListener(`FilePond:${event.substring(2)}`, this.handleEvent);
    });
    this.pond.destroy();
  }
}
FilePondComponent.ɵfac = function FilePondComponent_Factory(t) {
  return new (t || FilePondComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
FilePondComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FilePondComponent,
  selectors: [["file-pond"]],
  inputs: {
    options: "options",
    files: "files"
  },
  outputs: {
    oninit: "oninit",
    onwarning: "onwarning",
    onerror: "onerror",
    oninitfile: "oninitfile",
    onactivatefile: "onactivatefile",
    onaddfilestart: "onaddfilestart",
    onaddfileprogress: "onaddfileprogress",
    onaddfile: "onaddfile",
    onprocessfilestart: "onprocessfilestart",
    onprocessfileprogress: "onprocessfileprogress",
    onprocessfileabort: "onprocessfileabort",
    onprocessfilerevert: "onprocessfilerevert",
    onprocessfile: "onprocessfile",
    onprocessfiles: "onprocessfiles",
    onremovefile: "onremovefile",
    onpreparefile: "onpreparefile",
    onupdatefiles: "onupdatefiles",
    onreorderfiles: "onreorderfiles"
  },
  features: [i0.ɵɵNgOnChangesFeature],
  decls: 2,
  vars: 0,
  consts: [[1, "filepond--wrapper"], ["type", "file"]],
  template: function FilePondComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵelement(1, "input", 1);
      i0.ɵɵelementEnd();
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilePondComponent, [{
    type: Component,
    args: [{
      selector: "file-pond",
      encapsulation: ViewEncapsulation.None,
      template: "<div class=\"filepond--wrapper\">\n  <input type=\"file\"/>\n</div>"
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    options: [{
      type: Input
    }],
    files: [{
      type: Input
    }],
    oninit: [{
      type: Output
    }],
    onwarning: [{
      type: Output
    }],
    onerror: [{
      type: Output
    }],
    oninitfile: [{
      type: Output
    }],
    onactivatefile: [{
      type: Output
    }],
    onaddfilestart: [{
      type: Output
    }],
    onaddfileprogress: [{
      type: Output
    }],
    onaddfile: [{
      type: Output
    }],
    onprocessfilestart: [{
      type: Output
    }],
    onprocessfileprogress: [{
      type: Output
    }],
    onprocessfileabort: [{
      type: Output
    }],
    onprocessfilerevert: [{
      type: Output
    }],
    onprocessfile: [{
      type: Output
    }],
    onprocessfiles: [{
      type: Output
    }],
    onremovefile: [{
      type: Output
    }],
    onpreparefile: [{
      type: Output
    }],
    onupdatefiles: [{
      type: Output
    }],
    onreorderfiles: [{
      type: Output
    }]
  });
})();
class FilePondModule {}
FilePondModule.ɵfac = function FilePondModule_Factory(t) {
  return new (t || FilePondModule)();
};
FilePondModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FilePondModule
});
FilePondModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilePondModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [FilePondComponent],
      exports: [FilePondComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-filepond
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FilePondComponent, FilePondModule };
