import * as i0 from '@angular/core';
import { EventEmitter, Injectable, Component, Input, Pipe, NgModule } from '@angular/core';
import { getErrorMessage, createWorkbookFromSheet, DefaultSubmitSettings, JobHandler, SheetHandler, handlePostMessage } from '@flatfile/embedded-utils';
import { FlatfileClient } from '@flatfile/api';
import * as i1$1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { FlatfileListener, Browser } from '@flatfile/listener';
import { recordHook } from '@flatfile/plugin-record-hook';
import * as i1 from '@angular/platform-browser';
const _c0 = a0 => ({
  "flatfile_displayAsModal": a0
});
const _c1 = a0 => ({
  "flatfile_iFrameContainer": a0
});
function SpaceFrame_space_close_modal_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "space-close-modal", 7);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("spaceCloseModalProps", ctx_r0.spaceCloseModalProps);
  }
}
function SpaceFrame_iframe_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "iframe", 8);
    i0.ɵɵpipe(1, "safe");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(6, _c1, ctx_r0.spaceFrameProps.mountElement))("src", i0.ɵɵpipeBind1(1, 4, ctx_r0.spaceFrameProps.spaceUrl), i0.ɵɵsanitizeResourceUrl)("ngStyle", ctx_r0.iframeStyle);
    i0.ɵɵattribute("data-testid", ctx_r0.spaceFrameProps.mountElement && "flatfile_iFrameContainer");
  }
}
function Space_div_0_spinner_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "spinner");
  }
}
function Space_div_0_div_2_space_frame_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "space-frame", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("loading", ctx_r0.loading)("spaceFrameProps", ctx_r0.spaceFrameProps);
  }
}
function Space_div_0_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, Space_div_0_div_2_space_frame_1_Template, 1, 2, "space-frame", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.spaceFrameProps.localAccessToken);
  }
}
function Space_div_0_ng_template_3_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6)(1, "h3", 7);
    i0.ɵɵtext(2, "Something Went Wrong");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "p", 8);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.error == null ? null : ctx_r0.error.message, " ");
  }
}
function Space_div_0_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Space_div_0_ng_template_3_div_0_Template, 5, 1, "div", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", ctx_r0.error);
  }
}
function Space_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, Space_div_0_spinner_1_Template, 1, 0, "spinner", 1)(2, Space_div_0_div_2_Template, 2, 1, "div", 2)(3, Space_div_0_ng_template_3_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const error_content_r2 = i0.ɵɵreference(4);
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.loading);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.spaceFrameProps !== undefined)("ngIfElse", error_content_r2);
  }
}
const authenticate = (key, apiUrl = 'https://platform.flatfile.com/api') => new FlatfileClient({
  token: key,
  environment: `${apiUrl}/v1`
});
const getSpace = async spaceProps => {
  const {
    space,
    apiUrl,
    environmentId,
    spaceUrl = 'https://platform.flatfile.com/s/'
  } = spaceProps;
  let spaceResponse;
  let workbookResponse;
  try {
    if (!space?.id) {
      throw new Error('Missing required ID for Space');
    }
    if (!space?.accessToken) {
      throw new Error('Missing required accessToken for Space');
    }
    if (!environmentId) {
      throw new Error('Missing required environment id');
    }
    const limitedAccessApi = authenticate(space?.accessToken, apiUrl);
    try {
      spaceResponse = await limitedAccessApi.spaces.get(space?.id);
      workbookResponse = await limitedAccessApi.workbooks.list({
        spaceId: space?.id
      });
    } catch (error) {
      throw new Error(`Failed to get space: ${getErrorMessage(error)}`);
    }
    if (!spaceResponse.data.accessToken) {
      throw new Error('Failed to retrieve accessToken');
    }
    if (!spaceResponse.data.guestLink) {
      const guestLink = `${spaceUrl}space/${space?.id}?token=${spaceResponse.data.accessToken}`;
      spaceResponse.data.guestLink = guestLink;
    }
    return {
      space: spaceResponse,
      workbook: workbookResponse.data
    };
  } catch (error) {
    const message = getErrorMessage(error);
    console.error(`Failed to initialize space: ${message}`);
    throw error;
  }
};
const useInitializeSpace = flatfileOptions => {
  let space;
  const initializeSpace = async () => {
    try {
      const {
        publishableKey,
        environmentId,
        name = 'Embedded Space',
        spaceBody,
        apiUrl,
        spaceUrl = 'https://platform.flatfile.com/s/',
        workbook,
        sheet,
        onSubmit
      } = flatfileOptions;
      if (!publishableKey) {
        throw new Error('Missing required publishable key');
      }
      const limitedAccessApi = authenticate(publishableKey, apiUrl);
      const spaceRequestBody = {
        name,
        autoConfigure: false,
        ...spaceBody
      };
      let createdWorkbook = workbook;
      if (!createdWorkbook && !sheet) {
        spaceRequestBody.autoConfigure = true;
      }
      if (!createdWorkbook && sheet) {
        createdWorkbook = createWorkbookFromSheet(sheet, !!onSubmit);
      }
      try {
        space = await limitedAccessApi.spaces.create({
          ...(environmentId ? {
            environmentId
          } : {}),
          ...spaceRequestBody
        });
      } catch (error) {
        throw new Error(`Failed to create space: ${getErrorMessage(error)}`);
      }
      if (!space) {
        throw new Error(`Failed to create space: Error parsing token: ${publishableKey}`);
      }
      if (!space.data.accessToken) {
        throw new Error('Failed to retrieve accessToken');
      }
      if (!space.data.guestLink) {
        const guestLink = `${spaceUrl}space/${space.data.id}?token=${space.data.accessToken}`;
        space.data.guestLink = guestLink;
      }
      return {
        space,
        workbook: createdWorkbook
      };
    } catch (error) {
      const message = getErrorMessage(error);
      console.error(`Failed to initialize space: ${message}`);
      throw error;
    }
  };
  return {
    space,
    initializeSpace
  };
};
class SpaceService {
  constructor() {
    this.signal = new EventEmitter();
  }
  OpenEmbed() {
    setTimeout(() => this.signal.emit(true), 1);
  }
  static {
    this.ɵfac = function SpaceService_Factory(t) {
      return new (t || SpaceService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SpaceService,
      factory: SpaceService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpaceService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
const addSpaceInfo = async (spaceProps, spaceId, api) => {
  const {
    workbook,
    environmentId,
    document,
    themeConfig,
    sidebarConfig,
    spaceInfo,
    userInfo
  } = spaceProps;
  try {
    if (workbook) {
      const localWorkbook = await api.workbooks.create({
        spaceId,
        ...(environmentId ? {
          environmentId
        } : {}),
        ...workbook
      });
      if (!localWorkbook || !localWorkbook.data || !localWorkbook.data.id) {
        throw new Error('Failed to create workbook');
      }
    }
    const updatedSpace = await api.spaces.update(spaceId, {
      ...(environmentId ? {
        environmentId
      } : {}),
      metadata: {
        theme: themeConfig,
        sidebarConfig: sidebarConfig || {
          showSidebar: false
        },
        userInfo,
        spaceInfo
      }
    });
    if (!updatedSpace) {
      throw new Error('Failed to update space');
    }
    if (document) {
      const createdDocument = await api.documents.create(spaceId, {
        title: document.title,
        body: document.body
      });
      if (!createdDocument || !createdDocument.data || !createdDocument.data.id) {
        throw new Error('Failed to create document');
      }
    }
  } catch (error) {
    const message = getErrorMessage(error);
    throw new Error(`Error adding workbook to space: ${message}`);
  }
};
const api = new FlatfileClient();
const createSimpleListener = ({
  onRecordHook,
  onSubmit,
  slug,
  submitSettings
}) => FlatfileListener.create(client => {
  if (onRecordHook) {
    client.use(recordHook(slug, async (record, event) => onRecordHook(record, event)));
  }
  if (onSubmit) {
    const onSubmitSettings = {
      ...DefaultSubmitSettings,
      ...submitSettings
    };
    client.filter({
      job: 'workbook:simpleSubmitAction'
    }, configure => {
      configure.on('job:ready', async event => {
        const {
          jobId,
          spaceId,
          workbookId
        } = event.context;
        try {
          await api.jobs.ack(jobId, {
            info: 'Starting job',
            progress: 10
          });
          const job = new JobHandler(jobId);
          const {
            data: workbookSheets
          } = await api.sheets.list({
            workbookId
          });
          // this assumes we are only allowing 1 sheet here (which we've talked about doing initially)
          const sheet = new SheetHandler(workbookSheets[0].id);
          if (onSubmit) {
            await onSubmit({
              job,
              sheet,
              event
            });
          }
          await api.jobs.complete(jobId, {
            outcome: {
              message: 'complete'
            }
          });
          if (onSubmitSettings.deleteSpaceAfterSubmit) {
            await api.spaces.archiveSpace(spaceId);
          }
        } catch (error) {
          if (jobId) {
            await api.jobs.cancel(jobId);
          }
          console.error('Error:', error.stack);
        }
      });
    });
  }
});
const getIframeStyles = styles => styles ?? {
  width: '100%',
  height: '750px',
  borderWidth: 0,
  borderRadius: '20px',
  background: '#fff',
  padding: '16px'
};
const getContainerStyles = isModal => {
  if (isModal) {
    return {
      width: 'calc(100% - 100px)',
      height: 'calc(100vh - 40px)',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 1000,
      backgroundColor: 'rgba(0,0,0,0.2)',
      display: 'flex',
      padding: '50px'
    };
  }
  return {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
};
class SpaceCloseModal {
  constructor() {
    this.title = 'space-close-modal';
    this.spaceCloseModalProps = {};
  }
  ngOnInit() {}
  static {
    this.ɵfac = function SpaceCloseModal_Factory(t) {
      return new (t || SpaceCloseModal)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SpaceCloseModal,
      selectors: [["space-close-modal"]],
      inputs: {
        spaceCloseModalProps: "spaceCloseModalProps"
      },
      decls: 14,
      vars: 4,
      consts: [["data-testid", "close-confirm-modal", 1, "flatfile_outer-shell"], [1, "flatfile_inner-shell"], [1, "flatfile_modal"], [1, "flatfile_modal-heading"], [1, "flatfile_modal-text"], [1, "flatfile_button-group"], [1, "flatfile_button", "flatfile_secondary", 3, "click"], [1, "flatfile_button", "flatfile_primary", 3, "click"]],
      template: function SpaceCloseModal_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "div", 3);
          i0.ɵɵtext(4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "div", 4);
          i0.ɵɵtext(6);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "div", 5)(8, "button", 6);
          i0.ɵɵlistener("click", function SpaceCloseModal_Template_button_click_8_listener() {
            return ctx.spaceCloseModalProps.onCancel();
          });
          i0.ɵɵelementStart(9, "div");
          i0.ɵɵtext(10);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(11, "button", 7);
          i0.ɵɵlistener("click", function SpaceCloseModal_Template_button_click_11_listener() {
            return ctx.spaceCloseModalProps.onConfirm();
          });
          i0.ɵɵelementStart(12, "div");
          i0.ɵɵtext(13);
          i0.ɵɵelementEnd()()()()()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(4);
          i0.ɵɵtextInterpolate1(" ", ctx.spaceCloseModalProps.exitTitle || "Close Window", " ");
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate1(" ", ctx.spaceCloseModalProps.exitText || "Are you sure you want to exit? Any unsaved changes will be lost.", " ");
          i0.ɵɵadvance(4);
          i0.ɵɵtextInterpolate1(" ", ctx.spaceCloseModalProps.exitSecondaryButtonText || "Cancel", " ");
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1(" ", ctx.spaceCloseModalProps.exitPrimaryButtonText || "Confirm", " ");
        }
      },
      styles: [".flatfile_outer-shell[_ngcontent-%COMP%]{background-color:#0003;border-radius:5px;top:0;left:0;width:calc(100% - 40px);height:calc(100vh - 40px);padding:20px;display:block;overflow-y:auto;position:fixed;tab-size:4;z-index:1200}.flatfile_inner-shell[_ngcontent-%COMP%]{align-items:center;box-sizing:border-box;display:flex;justify-content:center;min-height:100%;padding:0;tab-size:4;text-align:left}.flatfile_modal[_ngcontent-%COMP%]{box-sizing:border-box;display:block;padding:1.5em;tab-size:4;text-align:left;background:#fff;min-width:500px;max-width:500px;border-radius:4px}.flatfile_modal-heading[_ngcontent-%COMP%]{font-size:1.225em;font-weight:600;margin-bottom:.4em;color:#090b2b}.flatfile_modal-text[_ngcontent-%COMP%]{font-size:14px;line-height:1.25em;margin-bottom:2em;color:#616a7d}.flatfile_button-group[_ngcontent-%COMP%]{display:flex;justify-content:flex-end}.flatfile_button[_ngcontent-%COMP%]{border:0;border-radius:1px;margin-left:15px;padding:8px 12px;cursor:pointer}.flatfile_primary[_ngcontent-%COMP%]{border:1px solid #4c48ef;background-color:#4c48ef;color:#fff}.flatfile_secondary[_ngcontent-%COMP%]{color:#616a7d}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpaceCloseModal, [{
    type: Component,
    args: [{
      selector: 'space-close-modal',
      template: "<div\ndata-testid=\"close-confirm-modal\"\nclass=\"flatfile_outer-shell\"\n>\n  <div class=\"flatfile_inner-shell\">\n    <div class=\"flatfile_modal\">\n      <div class=\"flatfile_modal-heading\">\n        {{this.spaceCloseModalProps.exitTitle || \"Close Window\"}}\n      </div>\n      \n      <div class=\"flatfile_modal-text\">\n        {{this.spaceCloseModalProps.exitText || \"Are you sure you want to exit? Any unsaved changes will be lost.\"}}\n      </div>\n\n      <div class=\"flatfile_button-group\">\n        <button\n          class=\"flatfile_button flatfile_secondary\"\n          (click)=\"this.spaceCloseModalProps.onCancel()\"\n        >\n          <div>\n            {{ this.spaceCloseModalProps.exitSecondaryButtonText || \"Cancel\" }}\n          </div>\n        </button>\n\n        <button\n          class=\"flatfile_button flatfile_primary\"\n          (click)=\"this.spaceCloseModalProps.onConfirm()\"\n        >\n          <div>\n            {{ this.spaceCloseModalProps.exitPrimaryButtonText || \"Confirm\" }}\n          </div>\n        </button>\n\n      </div>\n    </div>\n  </div>\n</div>",
      styles: [".flatfile_outer-shell{background-color:#0003;border-radius:5px;top:0;left:0;width:calc(100% - 40px);height:calc(100vh - 40px);padding:20px;display:block;overflow-y:auto;position:fixed;tab-size:4;z-index:1200}.flatfile_inner-shell{align-items:center;box-sizing:border-box;display:flex;justify-content:center;min-height:100%;padding:0;tab-size:4;text-align:left}.flatfile_modal{box-sizing:border-box;display:block;padding:1.5em;tab-size:4;text-align:left;background:#fff;min-width:500px;max-width:500px;border-radius:4px}.flatfile_modal-heading{font-size:1.225em;font-weight:600;margin-bottom:.4em;color:#090b2b}.flatfile_modal-text{font-size:14px;line-height:1.25em;margin-bottom:2em;color:#616a7d}.flatfile_button-group{display:flex;justify-content:flex-end}.flatfile_button{border:0;border-radius:1px;margin-left:15px;padding:8px 12px;cursor:pointer}.flatfile_primary{border:1px solid #4c48ef;background-color:#4c48ef;color:#fff}.flatfile_secondary{color:#616a7d}\n"]
    }]
  }], null, {
    spaceCloseModalProps: [{
      type: Input,
      args: [{
        required: true
      }]
    }]
  });
})();
class SafePipe {
  constructor(domSanitizer) {
    this.domSanitizer = domSanitizer;
  }
  transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
  static {
    this.ɵfac = function SafePipe_Factory(t) {
      return new (t || SafePipe)(i0.ɵɵdirectiveInject(i1.DomSanitizer, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "safe",
      type: SafePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SafePipe, [{
    type: Pipe,
    args: [{
      name: 'safe'
    }]
  }], function () {
    return [{
      type: i1.DomSanitizer
    }];
  }, null);
})();
class SpaceFrame {
  constructor() {
    this.title = 'space-frame';
    this.showExitWarnModal = false;
    this.spaceCloseModalProps = {};
    this.iframeWrapperStyle = {};
    this.iframeStyle = {};
    this.handlePostMessageInstance = () => {};
    this.spaceFrameProps = {};
    this.loading = false;
  }
  async created() {
    const {
      listener,
      apiUrl,
      closeSpace,
      workbook
    } = this.spaceFrameProps;
    const accessToken = this.spaceFrameProps.localAccessToken;
    const simpleListenerSlug = workbook?.sheets?.[0].slug || 'slug';
    const listenerInstance = listener || createSimpleListener({
      onRecordHook: this.spaceFrameProps.onRecordHook,
      onSubmit: this.spaceFrameProps.onSubmit,
      submitSettings: this.spaceFrameProps.submitSettings,
      slug: simpleListenerSlug
    });
    if (listenerInstance && typeof apiUrl === 'string') {
      listenerInstance.mount(new Browser({
        apiUrl,
        accessToken,
        fetchApi: fetch
      }));
    }
    const configuredHandlePostMessage = handlePostMessage(closeSpace, listenerInstance);
    window.addEventListener('message', configuredHandlePostMessage, false);
    this.handlePostMessageInstance = configuredHandlePostMessage;
  }
  async initializeSpace() {
    await this.created();
    const {
      publishableKey,
      workbook,
      environmentId,
      document,
      themeConfig,
      sidebarConfig,
      userInfo,
      spaceId,
      apiUrl = 'https://platform.flatfile.com/api'
    } = this.spaceFrameProps;
    const accessToken = this.spaceFrameProps.localAccessToken;
    if (publishableKey) {
      const fullAccessApi = authenticate(accessToken, apiUrl);
      await addSpaceInfo({
        publishableKey,
        workbook,
        environmentId,
        document,
        themeConfig,
        sidebarConfig,
        userInfo
      }, spaceId, fullAccessApi);
    }
  }
  openCloseModalDialog() {
    this.showExitWarnModal = true;
  }
  handleConfirm() {
    const {
      closeSpace,
      handleCloseInstance
    } = this.spaceFrameProps;
    if (closeSpace?.onClose && typeof closeSpace.onClose === 'function') {
      closeSpace.onClose({});
    }
    handleCloseInstance && handleCloseInstance();
  }
  handleCancel() {
    this.showExitWarnModal = false;
  }
  ngOnInit() {
    const {
      spaceId,
      exitText,
      exitTitle,
      exitPrimaryButtonText,
      exitSecondaryButtonText
    } = this.spaceFrameProps;
    this.iframeWrapperStyle = getContainerStyles(this.spaceFrameProps.displayAsModal || false);
    this.iframeStyle = getIframeStyles(this.spaceFrameProps.iframeStyles);
    if (!this.spaceFrameProps.localAccessToken) throw new Error('please wait until access token is recieved');
    const accessToken = this.spaceFrameProps.localAccessToken;
    window.CROSSENV_FLATFILE_API_KEY = accessToken;
    this.initializeSpace();
    this.spaceCloseModalProps = {
      onConfirm: this.handleConfirm.bind(this),
      onCancel: this.handleCancel.bind(this),
      exitText,
      exitTitle,
      exitPrimaryButtonText,
      exitSecondaryButtonText
    };
  }
  ngOnDestroy() {
    window.removeEventListener('message', this.handlePostMessageInstance);
  }
  static {
    this.ɵfac = function SpaceFrame_Factory(t) {
      return new (t || SpaceFrame)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SpaceFrame,
      selectors: [["space-frame"]],
      inputs: {
        spaceFrameProps: "spaceFrameProps",
        loading: "loading"
      },
      decls: 7,
      vars: 6,
      consts: [["data-testid", "space-contents", 1, "flatfile_iframe-wrapper", 3, "ngClass", "ngStyle"], [3, "spaceCloseModalProps", 4, "ngIf"], ["id", "flatfile_iframe", "allow", "clipboard-read; clipboard-write", 3, "ngClass", "src", "ngStyle", 4, "ngIf"], ["data-testid", "flatfile-close-button", 1, "flatfile-close-button", 2, "position", "absolute", "margin", "30px", 3, "click"], ["xmlns", "http://www.w3.org/2000/svg", "width", "24", "height", "24", "viewBox", "0 0 100 100"], ["x1", "10", "y1", "10", "x2", "90", "y2", "90", "stroke", "white", "stroke-width", "10"], ["x1", "10", "y1", "90", "x2", "90", "y2", "10", "stroke", "white", "stroke-width", "10"], [3, "spaceCloseModalProps"], ["id", "flatfile_iframe", "allow", "clipboard-read; clipboard-write", 3, "ngClass", "src", "ngStyle"]],
      template: function SpaceFrame_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, SpaceFrame_space_close_modal_1_Template, 1, 1, "space-close-modal", 1)(2, SpaceFrame_iframe_2_Template, 2, 8, "iframe", 2);
          i0.ɵɵelementStart(3, "div", 3);
          i0.ɵɵlistener("click", function SpaceFrame_Template_div_click_3_listener() {
            return ctx.openCloseModalDialog();
          });
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(4, "svg", 4);
          i0.ɵɵelement(5, "line", 5)(6, "line", 6);
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(4, _c0, ctx.spaceFrameProps.displayAsModal))("ngStyle", ctx.iframeWrapperStyle);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.showExitWarnModal);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.spaceFrameProps.spaceUrl);
        }
      },
      dependencies: [i1$1.NgClass, i1$1.NgIf, i1$1.NgStyle, SpaceCloseModal, SafePipe],
      styles: [".flatfile_displayAsModal[_ngcontent-%COMP%]   #flatfile_iframe[_ngcontent-%COMP%]{border-radius:5px;background:#fff}.flatfile_displayAsModal[_ngcontent-%COMP%]   .flatfile-close-button[_ngcontent-%COMP%]{position:absolute;z-index:10;top:35px;right:35px;display:flex;justify-content:center;width:25px;align-items:center;border-radius:100%;cursor:pointer;border:none;background:#000;box-shadow:0 0 10px #00000080;animation:glow 1.5s linear infinite alternate;transition:box-shadow .3s ease;height:25px}.flatfile_displayAsModal[_ngcontent-%COMP%]   .flatfile-close-button[_ngcontent-%COMP%]:hover{box-shadow:0 0 10px #000c}.flatfile_displayAsModal[_ngcontent-%COMP%]   .flatfile-close-button[_ngcontent-%COMP%]   svg[_ngcontent-%COMP%]{fill:#616a7d;width:10px}.flatfile_iframe-wrapper[_ngcontent-%COMP%]{min-width:768px;min-height:600px;width:992px;height:600px}.flatfile_iframe-wrapper.flatfile_displayAsModal[_ngcontent-%COMP%]{box-sizing:content-box;position:fixed;top:0;left:0;width:calc(100% - 60px);max-width:100vw;height:calc(100vh - 60px);padding:30px;background:#0003;z-index:1000}.flatfile-close-button[_ngcontent-%COMP%]{text-align:center;position:relative;top:20px;right:-20px;width:25px;height:25px;border-radius:100%;cursor:pointer;border:none;background:#000;box-shadow:0 0 10px #00000080;animation:glow 1.5s linear infinite alternate;transition:box-shadow .3s ease}.flatfile-close-button[_ngcontent-%COMP%]:hover{box-shadow:0 0 10px #000c}.flatfile-close-button[_ngcontent-%COMP%]   svg[_ngcontent-%COMP%]{fill:#d3d3d3;width:10px}#flatfile_iframe[_ngcontent-%COMP%]{border-width:0px;width:100%;height:100%;position:relative}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpaceFrame, [{
    type: Component,
    args: [{
      selector: 'space-frame',
      template: "<div class=\"flatfile_iframe-wrapper\" [ngClass]=\"{ 'flatfile_displayAsModal': this.spaceFrameProps.displayAsModal }\"\n  data-testid=\"space-contents\" [ngStyle]=\"this.iframeWrapperStyle\">\n  <space-close-modal *ngIf=\"this.showExitWarnModal\" [spaceCloseModalProps]=\"this.spaceCloseModalProps\" />\n  <iframe *ngIf=\"this.spaceFrameProps.spaceUrl\"\n    [ngClass]=\"{ 'flatfile_iFrameContainer': this.spaceFrameProps.mountElement }\"\n    [attr.data-testid]=\"this.spaceFrameProps.mountElement && 'flatfile_iFrameContainer'\"\n    [src]=\"this.spaceFrameProps.spaceUrl | safe\" id=\"flatfile_iframe\" allow=\"clipboard-read; clipboard-write\"\n    [ngStyle]=\"this.iframeStyle\">\n  </iframe>\n\n  <div (click)=\"this.openCloseModalDialog()\" data-testid=\"flatfile-close-button\" class=\"flatfile-close-button\"\n    style=\"position: absolute; margin: 30px;\">\n    <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 100 100\">\n      <line x1=\"10\" y1=\"10\" x2=\"90\" y2=\"90\" stroke=\"white\" :stroke-width=\"10\" />\n      <line x1=\"10\" y1=\"90\" x2=\"90\" y2=\"10\" stroke=\"white\" :stroke-width=\"10\" />\n    </svg>\n  </div>\n</div>",
      styles: [".flatfile_displayAsModal #flatfile_iframe{border-radius:5px;background:#fff}.flatfile_displayAsModal .flatfile-close-button{position:absolute;z-index:10;top:35px;right:35px;display:flex;justify-content:center;width:25px;align-items:center;border-radius:100%;cursor:pointer;border:none;background:#000;box-shadow:0 0 10px #00000080;animation:glow 1.5s linear infinite alternate;transition:box-shadow .3s ease;height:25px}.flatfile_displayAsModal .flatfile-close-button:hover{box-shadow:0 0 10px #000c}.flatfile_displayAsModal .flatfile-close-button svg{fill:#616a7d;width:10px}.flatfile_iframe-wrapper{min-width:768px;min-height:600px;width:992px;height:600px}.flatfile_iframe-wrapper.flatfile_displayAsModal{box-sizing:content-box;position:fixed;top:0;left:0;width:calc(100% - 60px);max-width:100vw;height:calc(100vh - 60px);padding:30px;background:#0003;z-index:1000}.flatfile-close-button{text-align:center;position:relative;top:20px;right:-20px;width:25px;height:25px;border-radius:100%;cursor:pointer;border:none;background:#000;box-shadow:0 0 10px #00000080;animation:glow 1.5s linear infinite alternate;transition:box-shadow .3s ease}.flatfile-close-button:hover{box-shadow:0 0 10px #000c}.flatfile-close-button svg{fill:#d3d3d3;width:10px}#flatfile_iframe{border-width:0px;width:100%;height:100%;position:relative}\n"]
    }]
  }], null, {
    spaceFrameProps: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    loading: [{
      type: Input,
      args: [{
        required: true
      }]
    }]
  });
})();
class Spinner {
  static {
    this.ɵfac = function Spinner_Factory(t) {
      return new (t || Spinner)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: Spinner,
      selectors: [["spinner"]],
      decls: 2,
      vars: 0,
      consts: [["data-testid", "spinner-icon", 1, "ff_spinner"]],
      template: function Spinner_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div");
          i0.ɵɵelement(1, "div", 0);
          i0.ɵɵelementEnd();
        }
      },
      styles: [".ff_spinner[_ngcontent-%COMP%]{border:4px solid rgba(255,255,255,.7);border-top:4px solid #4c48ef;border-radius:50%;width:50px;height:50px;animation:_ngcontent-%COMP%_spin 1s linear infinite;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:999}.spinner[_ngcontent-%COMP%]{border:4px solid rgba(255,255,255,.7);border-top:4px solid #4c48ef;border-radius:50%;width:50px;height:50px;animation:_ngcontent-%COMP%_spin 1s linear infinite;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:999}@keyframes _ngcontent-%COMP%_spin{0%{transform:rotate(0)}to{transform:rotate(360deg)}}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Spinner, [{
    type: Component,
    args: [{
      selector: 'spinner',
      template: "<div>\n  <div\n    class=\"ff_spinner\"\n    data-testid=\"spinner-icon\"\n  ></div>\n</div>",
      styles: [".ff_spinner{border:4px solid rgba(255,255,255,.7);border-top:4px solid #4c48ef;border-radius:50%;width:50px;height:50px;animation:spin 1s linear infinite;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:999}.spinner{border:4px solid rgba(255,255,255,.7);border-top:4px solid #4c48ef;border-radius:50%;width:50px;height:50px;animation:spin 1s linear infinite;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:999}@keyframes spin{0%{transform:rotate(0)}to{transform:rotate(360deg)}}\n"]
    }]
  }], null, null);
})();
class Space {
  constructor(appService) {
    this.appService = appService;
    this.spaceProps = {};
    this.openDirectly = false;
    this.title = 'Space';
    this.loading = false;
    this.closeInstance = false;
    this.handleCloseInstance = () => {
      this.closeInstance = true;
    };
    this.initSpace = async spaceProps => {
      this.closeInstance = false;
      const {
        space,
        initializeSpace
      } = useInitializeSpace(spaceProps);
      try {
        this.loading = true;
        const {
          space,
          workbook
        } = this.spaceProps.publishableKey ? await initializeSpace() : await getSpace(spaceProps);
        const {
          id: spaceId,
          accessToken,
          guestLink
        } = space.data;
        if (!spaceId || typeof spaceId !== 'string') {
          throw new Error('Missing spaceId from space response');
        }
        if (!guestLink || typeof guestLink !== 'string') {
          throw new Error('Missing guest link from space response');
        }
        if (!accessToken || typeof accessToken !== 'string') {
          throw new Error('Missing access token from space response');
        }
        this.localSpaceData = {
          spaceId,
          spaceUrl: guestLink,
          localAccessToken: accessToken
        };
        this.loading = false;
        this.spaceFrameProps = {
          ...this.spaceProps,
          ...this.localSpaceData,
          apiUrl: spaceProps.apiUrl || 'https://platform.flatfile.com/api',
          workbook,
          handleCloseInstance: this.handleCloseInstance
        };
      } catch (error) {
        this.loading = false;
        this.error = error;
        throw new Error(`An error has occurred: ${error}`);
      }
    };
  }
  async ngOnInit() {
    if (!this.spaceProps) throw new Error("Please define the space props");
    if (this.openDirectly) {
      await this.initSpace(this.spaceProps);
    } else {
      this.appService.signal.subscribe(async event => {
        if (event) {
          await this.initSpace(this.spaceProps);
        }
      });
    }
  }
  static {
    this.ɵfac = function Space_Factory(t) {
      return new (t || Space)(i0.ɵɵdirectiveInject(SpaceService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: Space,
      selectors: [["flatfile-space"]],
      inputs: {
        spaceProps: "spaceProps",
        openDirectly: "openDirectly"
      },
      decls: 1,
      vars: 1,
      consts: [["error_content", ""], [4, "ngIf"], [4, "ngIf", "ngIfElse"], [3, "loading", "spaceFrameProps", 4, "ngIf"], [3, "loading", "spaceFrameProps"], ["class", "ff_error_container", 4, "ngIf"], [1, "ff_error_container"], [1, "ff_error_heading"], [1, "ff_error_text"]],
      template: function Space_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, Space_div_0_Template, 5, 3, "div", 1);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", !ctx.closeInstance);
        }
      },
      dependencies: [i1$1.NgIf, SpaceFrame, Spinner],
      styles: [".flatfile_iframe-wrapper[_ngcontent-%COMP%]{background-color:#fff;color:#404857;font-family:Proxima Nova,Helvetica,sans-serif;font-size:15px;line-height:1.5}.ff_error_container[_ngcontent-%COMP%]{display:flex;flex-direction:column;margin:16px}.ff_error_heading[_ngcontent-%COMP%]{display:flex;margin-bottom:0}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Space, [{
    type: Component,
    args: [{
      selector: 'flatfile-space',
      template: "<div *ngIf=\"!this.closeInstance\">\n  <spinner *ngIf=\"this.loading\"/>\n\n  <div *ngIf=\"this.spaceFrameProps !== undefined;else error_content\">\n    <space-frame *ngIf=\"this.spaceFrameProps.localAccessToken\" [loading]=\"this.loading\" [spaceFrameProps]=\"this.spaceFrameProps\" />\n  </div>\n  <ng-template #error_content>\n    <div *ngIf=\"this.error\" class=\"ff_error_container\">\n      <h3 class=\"ff_error_heading\">Something Went Wrong</h3>\n      <p class=\"ff_error_text\">\n        {{this.error?.message}}\n      </p>\n    </div>\n  </ng-template>\n</div>",
      styles: [".flatfile_iframe-wrapper{background-color:#fff;color:#404857;font-family:Proxima Nova,Helvetica,sans-serif;font-size:15px;line-height:1.5}.ff_error_container{display:flex;flex-direction:column;margin:16px}.ff_error_heading{display:flex;margin-bottom:0}\n"]
    }]
  }], function () {
    return [{
      type: SpaceService
    }];
  }, {
    spaceProps: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    openDirectly: [{
      type: Input
    }]
  });
})();
class SpaceModule {
  static {
    this.ɵfac = function SpaceModule_Factory(t) {
      return new (t || SpaceModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SpaceModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [SpaceService],
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpaceModule, [{
    type: NgModule,
    args: [{
      declarations: [Space, SpaceFrame, SpaceCloseModal, SafePipe, Spinner],
      imports: [CommonModule],
      providers: [SpaceService],
      exports: [Space]
    }]
  }], null, null);
})();

/*
 * Public API Surface of flatfile
 */

/**
 * Generated bundle index. Do not edit.
 */

export { Space, SpaceModule, SpaceService };
